<template>
  <b-container>
    <div class="animated fadeIn">
      <b-form @submit.stop.prevent="onSubmit">
        <b-row>
          <b-col sm="12">
            <v-client-table
              :columns="columns"
              :data="categoryList"
              :options="options"
              id="dataTable"
              ref="dataTable"
              class="dataGridRowHeight"
              v-if="$auth.hasAuth($options, 'list')"
            >
              <template slot="active" slot-scope="props">
                <CheckBox
                  v-model="props.row.isChecked"
                  :defaultValue="props.row.isChecked"
                  @input="(val) => changed(props.row)"
                ></CheckBox>
              </template>
            </v-client-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="text-right">
            <div class="form-actions">
              <BrandButton
                buttonVariant="success"
                buttonIcon="fa fa-save"
                @click="save"
                v-if="$auth.hasAuth($options, 'save')"
                :text="$t('save')"
              />
              <BrandButton
                buttonVariant="secondary"
                buttonIcon="fa fa-check-square-o"
                @click="doSelectAll(!selectAllCategories)"
                :text="$t('selectAll')"
              />
            </div>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>
</template>

<script>
import http from "../../shared/http";
import toast from "../../shared/toast-helper";
import CheckBox from "../../widgets/form/CustomCheckBox";
import BrandButton from "../../widgets/form/CustomBrandButton";
import gridOptions from "../../shared/grid-options";
import { ClientTable } from "vue-tables-2";
export default {
  name: "UserAssignCategories",
  props: {
    userId: { type: Number, required: true },
  },
  components: {
    CheckBox,
    ClientTable,
    BrandButton,
  },
  methods: {
    doSelectAll(state) {
      for (let i = 0; i < this.categoryList.length; i++) {
        this.categoryList[i].isChecked = state;
      }
      this.selectAllCategories = state;
    },
    undoChanges() {
      this.categoryList = [];
      this.categoryList = JSON.parse(JSON.stringify(this.orgList));
      this.$refs.dataTable.resetQuery();
    },
    changed(val) {
      let itemIndex = this.categoryList.findIndex((x) => x.id == val.id);
      this.categoryList[itemIndex].isChecked = val.isChecked;
    },
    isChanged() {
      for (let i = 0; i < this.categoryList.length; i++) {
        if (this.categoryList[i].isChecked != this.orgList[i].isChecked) {
          return true;
          break;
        }
      }
      return false;
    },
    loadData() {
      this.categoryList = [];
      this.getCategories();
    },
    save() {
      let path = "UserCategoryRelations/update";
      let categoryIds = [];
      this.categoryList.forEach((element) => {
        if (element.isChecked) {
          categoryIds.push(element.id);
        }
      });
      let model = {
        id: 0,
        userId: this.userId,
        categoryIds: categoryIds,
      };
      http.post(path, model).then((result) => {
        if (result && result.success) {
          toast.success(result.message);
          this.orgList = [];
          this.orgList = JSON.parse(JSON.stringify(this.categoryList));
        }
      });
    },
    getCategories() {
      let path = `UserCategoryRelations/getbyuserid?userId=${this.userId}`;
      this.currentCategories = [];
      http.get(path).then((response) => {
        if (response) this.currentCategories = response;
        this.getAllCategories();
      });
    },
    getAllCategories() {
      let allCategories = [];
      http.get("Categories/getlist").then((result) => {
        allCategories = result;
        allCategories.forEach((element) => {
          if (element.parentCategoryId == null) {
            let isChecked =
              this.currentCategories.findIndex(
                (x) => x.categoryId == element.id
              ) >= 0;
            let temp = {
              id: element.id,
              title: element.categoryName,
              isChecked: isChecked,
            };
            this.categoryList.push(temp);
            this.orgList.push(JSON.parse(JSON.stringify(temp)));
          }
        });
      });
      this.$refs.dataTable.resetQuery();
    },
  },
  data() {
    return {
      categoryList: [],
      currentCategories: [],
      allCategories: [],
      selectAllCategories: false,
      orgList: [],
      columns: ["title", "active"],
      options: {
        ...gridOptions.getAll(),
        debounce: 100,
        headings: {
          title: this.$t("title"),
          active: this.$t("active"),
        },
      },
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    userId() {},
  },
};
</script>
<style scoped>
fieldset.form-group {
  margin-bottom: 0rem !important;
}
</style>