<template>
  <div class="animated fadeIn">
    <CustomModal
      :modalTitle="$t('save')"
      :button1Title="$t('save')"
      :button2Title="$t('cancel')"
      :show="saveModalClaim"
      @hide="saveModalClaim = false"
      @onButton1Click="
        () => {
          this.$refs.userClaim.save();
          saveModalClaim = false;
        }
      "
      @onButton2Click="
        () => {
          this.$refs.userClaim.undoChanges();
          saveModalClaim = false;
        }
      "
      :closeButton="false"
    >
      {{ $t("unsavedClaim") }}
    </CustomModal>
    <CustomModal
      :modalTitle="$t('save')"
      :button1Title="$t('save')"
      :button2Title="$t('cancel')"
      :show="saveModalCategory"
      @hide="saveModalCategory = false"
      @onButton1Click="
        () => {
          this.$refs.userCategory.save();
          saveModalCategory = false;
        }
      "
      @onButton2Click="
        () => {
          this.$refs.userCategory.undoChanges();
          saveModalCategory = false;
        }
      "
      :closeButton="false"
    >
      {{ $t("unsavedClaim") }}
    </CustomModal>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>{{
              user.id == 0 ? $t("newUser") : $t("updateUser")
            }}</strong>
          </div>
          <b-tabs
            pills
            card
            justified
            :vertical="$isMobile() == false"
            @activate-tab="(newTabIndex) => onTabChanged(newTabIndex)"
          >
            <b-tab active>
              <template #title>
                <i class="fa fa-user"></i>
                {{ $t("user") }}
              </template>
              <b-form @submit.stop.prevent="onSubmit" class="animated fadeIn">
                <b-row>
                  <b-col sm="6">
                    <b-col sm="12">
                      <ValidationableTextBox
                        prename="user"
                        id="firstName"
                        icon="fa fa-user"
                        v-model="$v.form.firstName.$model"
                        :form="$v.form.firstName"
                      />
                    </b-col>

                    <b-col sm="12">
                      <ValidationableTextBox
                        prename="user"
                        id="lastName"
                        icon="fa fa-user"
                        v-model="$v.form.lastName.$model"
                        :form="$v.form.lastName"
                      />
                    </b-col>
                    <b-col sm="12">
                      <ValidationableTextBox
                        prename="user"
                        icon="fa fa-envelope"
                        id="email"
                        v-model="$v.form.email.$model"
                        :form="$v.form.email"
                      />
                    </b-col>
                    <b-col sm="12" class="d-flex">
                      <CheckBox
                        v-model="form.active"
                        :value="form.active"
                        :defaultValue="form.active"
                        :label="$t('active')"
                        class="mr-3"
                      ></CheckBox>
                    </b-col>
                  </b-col>
                  <b-col sm="6">
                    <b-col sm="12">
                      <ValidationableTextBox
                        prename="user"
                        id="userName"
                        icon="fa fa-user"
                        v-model="$v.form.userName.$model"
                        :form="$v.form.userName"
                      />
                    </b-col>

                    <b-col sm="12">
                      <ValidationableTextBox
                        prename="user"
                        autoComplete="new-password"
                        id="password"
                        icon="fa fa-key"
                        type="password"
                        v-model="$v.form.password.$model"
                        :form="$v.form.password"
                      />
                    </b-col>
                    <b-col sm="12">
                      <ValidationableTextBox
                        prename="user"
                        id="repeatPassword"
                        autoComplete="new-password"
                        type="password"
                        icon="fa fa-key"
                        v-model="$v.form.repeatPassword.$model"
                        :form="$v.form.repeatPassword"
                      />
                    </b-col>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" class="text-right">
                    <div class="form-actions">
                      <BrandButton
                        buttonVariant="success"
                        buttonIcon="fa fa-save"
                        @click="save"
                        :text="$t('save')"
                      />

                      <BrandButton
                        buttonVariant="danger"
                        buttonIcon="fa fa-undo"
                        @click="back"
                        :text="$t('back')"
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab v-if="selectedUserId > 0">
              <template #title>
                <i class="fa fa-lock"></i>
                {{ $t("userClaims") }}
              </template>
              <UserClaim :userId="selectedUserId" ref="userClaim"
            /></b-tab>
            <b-tab v-if="selectedUserId > 0">
              <template #title>
                <i class="fa fa-list"></i>
                {{ $t("userCategories") }}
              </template>
              <UserAssignCategories :userId="selectedUserId" ref="userCategory"
            /></b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import http from "../../shared/http";
import userValidator from "../../validations/user-validator";
import toast from "../../shared/toast-helper";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import CheckBox from "../../widgets/form/CustomCheckBox";
import BrandButton from "../../widgets/form/CustomBrandButton";
import UserClaim from "../../widgets/user/UserClaim";
import UserAssignCategories from "../../widgets/user/UserAssignCategories";
import { validationMixin } from "vuelidate";
import CustomModal from "../../widgets/form/CustomModal";

export default {
  name: "UserAdd",
  components: {
    ValidationableTextBox,
    CheckBox,
    UserClaim,
    UserAssignCategories,
    CustomModal,
    BrandButton,
  },
  mixins: [validationMixin],
  validations: userValidator.getValidations(false),
  methods: {
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      let model = { ...this.user, ...this.form };
      let path = model.id > 0 ? "users/update" : "users/add";
      if (!model.id > 0) {
        model.isEmployee = true;
      }
      http.post(path, model).then((result) => {
        if (result && result.success) {
          if (path == "users/add") {
            this.selectedUserId = result.data;
          }
          toast.success(result.message);
        }
      });
    },
    onTabChanged(newTabIndex) {
      switch (newTabIndex) {
        case 0:
          this.saveModalClaim = this.$refs.userClaim.isChanged();
          this.saveModalCategory = this.$refs.userCategory.isChanged();
          break;
        case 1:
          this.saveModalCategory = this.$refs.userCategory.isChanged();
          break;
        case 2:
          this.saveModalClaim = this.$refs.userClaim.isChanged();
          break;
      }
    },
    back() {
      this.$router.back();
    },
    getUpdateForm(userId) {
      let path = `users/getbyid?userId=${userId}`;
      http.get(path).then((user) => {
        if (user) {
          this.user = user;
          this.form.firstName = user.firstName;
          this.form.lastName = user.lastName;
          this.form.email = user.email;
          this.form.userName = user.userName;
          this.form.active = user.active;
          this.form.isUpdating = true;
          this.validations = userValidator.getValidations();
        }
      });
    },
  },
  data() {
    return {
      selectedUserId: 0,
      saveModalClaim: false,
      saveModalCategory: false,
      form: {
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        password: "",
        repeatPassword: "",
        active: true,
        isUpdating: false,
      },
      user: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        password: "",
        active: true,
        isEmployee: true,
      },
    };
  },
  created() {
    let userId = this.$route.params.id ? this.$route.params.id : 0;
    this.selectedUserId = parseInt(userId);
    if (userId > 0) {
      this.getUpdateForm(userId);
    }
  },
};
</script>
